import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';
import Homepage from './home';
import { SWRConfig } from 'swr';
import { HomeProps } from '@/lib/inerfaces';
import { useTranslation } from 'next-i18next';
const Home: React.FC<HomeProps> = ({
  fallback
}) => {
  const {
    t
  } = useTranslation();
  return <>
            <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
                <title>{t('HOMEPAGE.SEO.TITLE')}</title>
                <meta name="description" content={t('HOMEPAGE.SEO.DESCRIPTION')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
                <meta name="keywords" content={t('HOMEPAGE.SEO.META_KEYWORDS')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
                <meta property="og:title" content={t('HOMEPAGE.SEO.OG_TITLE')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
                <meta property="og:description" content={t('HOMEPAGE.SEO.OG_DESCRIPTION')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
                <meta property="og:image" content="https://ofertirai.me/images/header/icon.svg" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
                <meta property="og:url" content="https://ofertirai.me/" data-sentry-element="meta" data-sentry-source-file="index.tsx" />

                <meta name="twitter:title" content={t('HOMEPAGE.SEO.OG_TITLE')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
                <meta name="twitter:description" content={t('HOMEPAGE.SEO.OG_DESCRIPTION')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
                <meta name="twitter:images" content="https://ofertirai.me/images/header/icon.svg" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
                <meta name="twitter:site" content="@Ofertirai_Me" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
                <meta name="twitter:card" content="summary_large_image" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
            </Head>
            <SWRConfig value={{
      fallback
    }} data-sentry-element="SWRConfig" data-sentry-source-file="index.tsx">
                <Homepage data-sentry-element="Homepage" data-sentry-source-file="index.tsx" />
            </SWRConfig>
        </>;
};

// Explicitly type the context parameter
export const getStaticProps: GetStaticProps = async ({
  locale
}) => {
  // const locale = context.locale || "bg"; // Default to 'bg' if locale is undefined

  return {
    props: {
      ...(await serverSideTranslations(locale || 'bg', ['common']))
    }
  };
};
export default Home;